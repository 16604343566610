var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-panel"},[_c('div',{staticClass:"mb-s h2"},[_vm._v(" "+_vm._s(_vm.i18nSuivi.compteurNumber)+_vm._s(_vm.$attrs.content.numCompteur + 1)+" ")]),(_vm.$attrs.content.ean !== null)?_c('span',[_vm._v(_vm._s(_vm.i18nSuivi.ean)+" "+_vm._s(_vm.$attrs.content.ean))]):_vm._e(),_c('hr'),_vm._v(" "+_vm._s(_vm.$attrs.content.compteur.libInfo)+" "),_c('br'),(!_vm.fileSend)?_c('span',[_vm._v(_vm._s(_vm.i18nSuivi.uploadPanelTxt))]):_vm._e(),(_vm.requestFiles.file !== undefined)?_c('span',[_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkContentType(_vm.requestFiles.file.file.type)),expression:"!checkContentType(requestFiles.file.file.type)"}],staticStyle:{"color":"red"}},[_c('center',[_vm._v(" "+_vm._s(_vm.i18nSuivi.errorType)+" ")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.requestFiles.file.file.size > _vm.sizeMax),expression:"requestFiles.file.file.size > sizeMax"}],staticStyle:{"color":"red"}},[_c('center',[_vm._v(_vm._s(_vm.i18nSuivi.errorUploadSize))])],1)]):_vm._e(),(!_vm.fileSend)?_c('div',{staticClass:"bg-light pt-3 box",attrs:{"id":"profile-pic-demo"}},[_c('upload',{ref:"fileAgent",staticClass:"profile-pic-upload-block",attrs:{"theme":"list","accept":"image/jpg,image/jpeg,.dwg,.pdf,.dxf,.png","maxSize":"10MB","mutiple":_vm.requestFiles.multiple,"isMutiple":_vm.requestFiles.multiple,"deletable":true,"uploadUrl":_vm.endpointUrl,"errorText":{
        type: _vm.i18nSuivi.errorType,
        size: _vm.i18nSuivi.errorUploadSize,
      },"disabled":_vm.activeLoader},model:{value:(_vm.requestFiles.file),callback:function ($$v) {_vm.$set(_vm.requestFiles, "file", $$v)},expression:"requestFiles.file"}}),(!_vm.fileSend)?_c('div',{staticClass:"submit-row"},[_c('g-action',{staticClass:"mb-s",attrs:{"disabled":_vm.disableUploadfile ||
          _vm.requestFiles.file == undefined ||
          !_vm.checkContentType(_vm.requestFiles.file.file.type) ||
          _vm.requestFiles.file.file.size > _vm.sizeMax,"content":{
          label: _vm.i18nSuivi.btnAjouter,
          tag: 'button',
          modifiers: ['big'],
        }},nativeOn:{"click":function($event){return _vm.uploadFile(
            $event,
            _vm.$attrs.content.idDossier,
            _vm.$attrs.content.compteur,
            _vm.$attrs.content.idCompteur,
            _vm.$attrs.content.numCompteur,
            _vm.$attrs.content.header,
            _vm.$attrs.content.renvoyer
          )}}}),(_vm.activeLoader)?_c('span',[_c('g-loader')],1):_vm._e()],1):_c('div',[_vm._v(_vm._s(_vm.i18nSuivi.sended))])],1):_c('div',[_vm._v(_vm._s(_vm.i18nSuivi.uploadPanelSucces))])])}
var staticRenderFns = []

export { render, staticRenderFns }