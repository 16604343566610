


































































































































































import { useState } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'
import 'vue-file-agent/dist/vue-file-agent.css'

export default defineComponent({
  name: 'upload-dossier',
  components: {},
  props: {
    isMutiple: {
      required: false,
      type: Boolean,
    },
  },

  setup() {
    /* eslint-disable */
    const { i18n, i18nSuivi } = useState('my', ['i18n', 'i18nSuivi'])

    return {
      i18nSuivi,
      i18n,
    }
  },
})
