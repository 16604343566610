





















































































































































/* eslint-disable */
import { defineComponent, reactive, ref } from '@vue/composition-api'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import Upload from '@/components/my/UploadDossier.vue'

export default defineComponent({
  name: 'upload-panel',
  components: { Upload },

  setup(props, ctx) {
    // global data
    const { user, chrome } = useState(['user', 'chrome'])
    const state = {
      ...useState('my', ['files', 'ownerData', 'customerData']),
    }
    const requestFiles = reactive({ ...state.files.value })
    const { SET_FILE, UPDATE_FILE } = useMutations('my', [
      'SET_FILE',
      'UPDATE_FILE',
    ])
    const { files, i18nSuivi } = useState('my', ['files', 'i18nSuivi'])
    const { hidePanel } = useActions('sidePanel', ['hidePanel'])
    const activeLoader = ref(false)
    const disableUploadfile = ref(false)
    const fileSend = ref(false)
    const contentType = [
      'application/pdf',
      'image/jpg',
      'image/png',
      'image/png,',
      'image/dwg',
      'image/dxf',
      'image/jpeg',
    ]
    const sizeMax = ref(10485760)

    const uploadFile = (
      e,
      id,
      compteur,
      idCompteur,
      numCpt,
      header,
      renvoyer
    ) => {
      e.preventDefault()
      //console.log(requestFiles.file.file)
      if (requestFiles.file === undefined) {
        alert('upload un fichier')
      }
      if (requestFiles.file.file.size > sizeMax) {
        alert('fichier impossible a envoyé, taille trop grande!')
      } else if (!checkContentType(requestFiles.file.file.type)) {
        alert('type de fichier invalide')
      } else {
        activeLoader.value = true
        disableUploadfile.value = true
        // Recupere le fichier upload et le transforme pour l'envoyer au WS77
        if (header) {
          idCompteur = id
        }
        axios
          .get(
            `${getApiUrl()}/file/send?Submit=false&DocumentName=` +
              compteur.codeInfo.trim() +
              '-' +
              compteur.libInfo.replaceAll("'", '´') +
              '-' +
              idCompteur +
              '.' +
              requestFiles.file.ext +
              `&IdDossier=${id}`
          )
          .then(response => {
            if (response.status === 200) {
              let customAxios = axios.create()
              delete customAxios.defaults.headers.common['Authorization']

              customAxios
                .put(response.data.putUrl, requestFiles.file.file, {
                  headers: {
                    'Content-Type': '*',
                  },
                })
                .then(response => {
                  if (response.status === 200) {
                    //console.log(fileToSend(numCpt, idCompteur, id))
                    if (fileToSend(numCpt, idCompteur, id, compteur.codeInfo) === 0) {
                      SET_FILE({
                        idDossier: id,
                        idCompteur: idCompteur,
                        idFile: compteur.codeInfo,
                        isSend: false,
                        index: numCpt,
                        value: compteur.valeurInfo,
                        header: header,
                        uploadAndSend: false,
                      })
                    } else {
                      UPDATE_FILE({
                        idDossier: id,
                        idCompteur: idCompteur,
                        idFile: compteur.codeInfo,
                        index: numCpt,
                        uploadAndSend: false,
                      })
                    }
                    activeLoader.value = false
                    fileSend.value = true
                    //hidePanel()
                  }
                })
            }
          })
          .catch(e => {
            hidePanel()
            //disableUploadfile.value = false
          })
      }
    }
    const fileToSend = (index, idCpt, id, idFile) => {
      let fileHeader = 0
      for (let i in files.value) {
        if (
          files.value[i].idDossier === id &&
          files.value[i].idCompteur === idCpt &&
          files.value[i].index === index &&
          files.value[i].idFile === idFile
        ) {
          fileHeader++
        }
      }
      return fileHeader
    }
    const checkContentType = myType => {
      if (contentType.includes(myType)) {
        return true
      }
      return false
    }

    return {
      user,
      chrome,
      requestFiles,
      sizeMax,
      endpointUrl: `${getApiUrl()}/rac/upload`,
      activeLoader,
      i18nSuivi,
      disableUploadfile,
      fileSend,
      uploadFile,
      checkContentType,
    }
  },
})
